/* 제목 부분 */
#res-top{
    margin-top: 3em;
    padding-bottom: 0.5em;
    text-align: center;
}
#res-title{font-weight:bold; font-size:2em;}
#res-text{font-size:1.2em;}

/* 이미지 */
#coll-img-wrapper{width:500px;}
#coll-img{width:100%;}

/* 아코디언 버튼 디자인 */
@import '~antd/dist/antd.css';
#coll{
    width: 52em;
    margin: auto;
}


#footer-empty{
    width: 100%;
    height: 5em;
}

/* 창 크기에 따른 변경 */
@media screen and (max-width : 800px){
    #coll{
        width: 100%;
    }
    #coll-img-wrapper{width:80%;}
}